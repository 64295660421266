<template>
   <div>
      <div
         v-if="type === 'card'"
         class="border border-gray-300 shadow rounded-2xl p-4 max-w-full w-full mx-auto"
      >
         <div class="animate-pulse flex space-x-4">
            <div class="rounded-full bg-slate-200 h-10 w-10"></div>
            <div class="flex-1 space-y-6 py-1">
               <div class="h-2 bg-slate-200 rounded"></div>
               <div class="space-y-3">
                  <div class="grid grid-cols-3 gap-4">
                     <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                  </div>
                  <div class="h-2 bg-slate-200 rounded"></div>
               </div>
            </div>
         </div>
      </div>
      <div
         v-if="type === 'text'"
         class="text-center flex flex-col justify-self-center justify-center p-4 max-w-full w-full mx-auto"
      >
         <div class="h-8 w-2/3 bg-gray-200 bg-opacity-20 mb-3"></div>
         <div class="h-6 w-2/3 bg-gray-200 bg-opacity-20"></div>
      </div>
      <div
         v-if="type === 'calendar'"
         class="grid grid-rows-12 h-72 border border-gray-500 rounded-xl border-opacity-50 gap-4 p-3">
         <div v-for="i in 10" :key="i" class="col-span-3 rounded-full bg-gray-200 bg-opacity-20"></div>
      </div>
   </div>
</template>
<script>
   export default {
      props: {
         type: {
            type: String,
            default: 'card',
         },
      },
      data() {
         return {};
      },
   };
</script>
